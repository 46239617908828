.social-icon {
  margin: 5px;
}

.social-icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-info {
  padding-bottom: 10px;
  padding-top: 15px;
}

.fingerprint-cutout {
  background-color: #0000AA;
  text-shadow: 0px 0px 5px white;
  color: white;
  font-size: 17px;
  font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace;
}

.fingerprint-container {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-word;
}