.main-os-logo-div {
  text-align: center;
}

.os-title {
  font-size: 30px;
  padding: 10px;
}

.os-subtitle {
  font-size: 20px;
}

.os-version {
  padding: 5px;
}

.os-copyright {
  padding-top: 10px;
  font-size: 11px;
  text-shadow: -1px -1px 1px white;
  opacity: 0.6;
}

.code-block {
  font-family: 'courier';
}