.popup-window-container {
  position: absolute;
  z-index: 4;

  touch-action: auto !important;

  left: 0;
  right: 0;

  top: -15px;

  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.inner-popup-window-container {
  margin-left: -10px;
  margin-right: -10px;
}

.window-shadow-primary {
  -webkit-box-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
  box-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
}

.window-title-text {
  padding-right: 20px;
  cursor: url(../resources/icons/pointers/grab.gif), auto;
}

.window-title-text:active {
  cursor: url(../resources/icons/pointers/grabbing.gif), auto;
}

.window-title-text img {
  pointer-events: none;
}

.popup-movable-header {
  cursor: url(../resources/icons/pointers/grab.gif), auto;
}

.popup-movable-header:active {
  cursor: url(../resources/icons/pointers/grabbing.gif), auto;
}