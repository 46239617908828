.awesome-gui-cutoutbg {
  background-color: #ffffff;
}

.awesome-gui-icons-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.website-name {
  margin-top: -25px;

  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.website-link {
  color: black;
  text-decoration: none;
}

.single-icon {
  padding: 10px;
  text-align: center;
  width: 90px;
}

.checkbox-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.main-categories-view {
  margin-top: 20px;
  padding-bottom: 25px;
}

.main-search-view {
  padding-bottom: 15px;
  margin-top: 10px;
}

.categories-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.choice-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: min(10px, 1vw);
}

.filter-buttons-container {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.radio-buttons {
  width: 33.3%;
}

.radio-buttons .left-button {
  border-radius: 20px 0px 0px 20px;
}

.radio-buttons .right-button {
  border-radius: 0px 20px 20px 0px;
}

@media only screen and (max-width: 430px) {
  .radio-buttons .left-button {
    height: 50px;
  }

  .radio-buttons .center-button {
    height: 50px;
  }

  .radio-buttons .right-button {
    height: 50px;
  }
}

.radio-buttons-container {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.desktop-number-overview {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #dbdeff;
  border: 1px solid;
  padding: 5px;

  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.61);
  font-size: 18px;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

@media screen and (max-width: 865px) {
  .single-icon {
    padding: 5px;
  }
}

.single-icon:hover .computer-icon {
  filter: grayscale(100%) brightness(5%) sepia(100%) hue-rotate(170deg) brightness(900%) saturate(500%);
}

.single-icon:hover .website-favicon {
  filter: grayscale(100%) brightness(5%) sepia(100%) hue-rotate(170deg) brightness(900%) saturate(500%);
}

.single-icon:hover .website-name span {
  background-color: #008dec;
  color: white;
}

.computer-icon {
  display: block;
  backface-visibility: hidden;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge*/
  user-select: none;
  /* Non-prefixed version, currently 
                                  not supported by any browser */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  pointer-events: none;
}

.website-favicon {
  position: relative;
  bottom: 60px;

  backface-visibility: hidden;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge*/
  user-select: none;
  /* Non-prefixed version, currently 
                                  not supported by any browser */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  pointer-events: none;
}

.toolbar-container {
  margin-top: -20px;
}

.screen-footer {
  padding: 8px;
  text-align: center;
  position: relative;
  text-shadow: -1px -1px 1px white;
}

.progress-content {
  height: 100%;
  line-height: 35px;

  position: absolute;

  top: 0;
  left: 0;

  overflow: hidden;
  background: #9c99c7;
}

.search-input {
  flex: 1;
}

.search-input input {
  font-size: 18px;
  font-weight: bold;
}
