.scheduledtv-container {
  height: 100%;
  position: absolute;    
  margin:auto;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: black;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.TV-output {
  color: #00FF00;
  padding: 10px;
  font-size:60px;
  font-weight: bold;
  position: absolute;
}

.scheduledtv-video-container {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  margin:auto;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.black-screen-tv {
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  margin:auto;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
