.copyright {
  font-size: 12px;
  position: absolute;
  text-shadow: -1px -1px 1px white;
  opacity: 0.6;
  filter: Alpha(opacity=60); /* IE8 and earlier */
}

.extra-copyright-info {
  display: inline;
}

@media only screen and (max-width: 480px)  {
  .copyright {
    font-size: 11px;
  }

  .extra-copyright-info {
    display: none;
  }
}