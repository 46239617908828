
.cutout-area {
  overflow: auto;
  overflow-y: hidden;
  background-color: black;

  text-shadow: 0px 0px 5px white;

  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace;
}

.project-item {
  padding: 5px;
}

.bulletpoint {
  text-shadow: 0px 0px 5px orange;
  color: orange;
  font-weight: bold;
}

.comment-text {
  text-shadow: 0px 0px 5px lime;
  color: lime;
  font-style: italic;
}

.project-item a {
  text-shadow: 0px 0px 5px lightskyblue;
  color: yellow;
}

.project-item a:hover {
  color: red;
}

.project-item a:visited {
  color: lightskyblue;
}

.prompt-area {
  cursor: url(../../resources/icons/pointers/pointer.gif), auto;
  overflow: 'hidden';
}
