.bottom-buttons-container {
  padding-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.bottom-buttons-wd {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}