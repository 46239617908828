.music-window-container {
  width: 330px;
}

.head-container {
  position: absolute;
  top: 35px;
  left: -4px;
  width: 342px;
  height: 394px;
  pointer-events: none;
}

.head-background {
  background: url(../../resources/images/head.gif) no-repeat;
  animation: hue 20s infinite linear;
  position: absolute;
  width: 342px;
  height: 394px;
}

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }
  
  to {
    filter: hue-rotate(360deg);
  }
}

.head-left-ear {
  position: absolute;
  width: 45px;
  height: 165px;
  top: 85px;

  pointer-events: all;
}

.head-right-ear {
  position: absolute;
  width: 45px;
  height: 165px;
  top: 85px;
  right: 2px;

  pointer-events: all;
}

.head-overlay {
  position: absolute;
  width: 246px;
  height: 395px;
  left: 48px;
  transform: rotate(1deg);
  border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%;

  cursor: url(../../resources/icons/pointers/grab.gif), auto;

  pointer-events: all;
}

.head-overlay:active {
  cursor: url(../../resources/icons/pointers/grabbing.gif), auto;
}

.player-head {
  position: absolute;
  width: 185px;
  bottom: 188px;
  left: 80px;
  pointer-events: all;
}

.head-buttons-container {
  display: flex;
  position: absolute;
  top: 14px;
  left: 136px;
  pointer-events: all;
}

.recently-discovered {
  padding-bottom: 10px;
}

.social-button-container {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}

.social-button {
  cursor: url(../../resources/icons/pointers/pointer.gif), auto;
}

.music-track-text {
  color: yellow;
  font-weight: bold;
  font-size: 20px;
  cursor: url(../../resources/icons/pointers/pointer.gif), auto;
}