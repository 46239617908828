@font-face {
  font-family: 'ITC Garamond Std Book Narrow';
  src: url('../../resources/fonts/ITCGaramondStd-BkNarrow.eot');
  src: url('../../resources/fonts/ITCGaramondStd-BkNarrow.eot?#iefix') format('embedded-opentype'),
      url('../../resources/fonts/ITCGaramondStd-BkNarrow.woff2') format('woff2'),
      url('../../resources/fonts/ITCGaramondStd-BkNarrow.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: monospace,monospace;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding-left: 20px;
}

blockquote {
  font-style: italic;
}

.blog-post-title {
  font-family: 'ITC Garamond Std Book Narrow';
  line-height: 1.1em;
  font-size: 2.6em;
  margin-bottom: 10px;
}

.blog-cutout {
  background-color: #ffffff;
}

.blogpost-wrapper {
  /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-word;

  padding: 15px;
}

.blog-footer-buttons {
  width: 33.3%;
}

.blog-footer-cut-out {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px)  {
  .blog-footer-buttons {
    width: 100%;
  }
}
