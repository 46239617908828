.button-item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin: 5px;
}

.first-row-icons {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px
}

.last-row-icons {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px
}

.cut-out {
  height: 300px;
  background-color: white;
  overflow: hidden;
}

@media only screen and (max-width: 865px)  {
  .cut-out {
    height: 450px;
  }
}

@media only screen and (max-width: 345px) {
  .cut-out { 
    height: 600px;
  }
}

.movable-icon {
  cursor: url(../resources/icons/pointers/grab.gif), auto;
}

.movable-icon:active {
  cursor: url(../resources/icons/pointers/grabbing.gif), auto;
}

.icon {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge*/
   user-select: none;          /* Non-prefixed version, currently 
                                  not supported by any browser */
   -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  height: 54px;
  pointer-events: none;
}

.icon-caption {
  font-weight: bold;
  display: block;
}

.colored-text {
  background-image: linear-gradient(to left, blue, rgb(255, 43, 43));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hue 1s infinite linear;
}

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }
  
  to {
    filter: hue-rotate(360deg);
  }
}