.cestino-message-container {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.cestino-message-text {
  display: block;
  font-size: 1.8em;
  font-weight: bold;

  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: space-around;
}

.action-button-container {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}