.bulb-window {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lightbulb-shadow {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 29px;
  top: 33px;
  border-radius:50%;
  -webkit-box-shadow: 0px 0px 100px 65px rgba(255,220,0,1);
  -moz-box-shadow: 0px 0px 100px 65px rgba(255,220,0,1);
  box-shadow: 0px 0px 100px 65px rgba(255,220,0,1);
}

.broken-lightbulb-shadow {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 29px;
  top: 33px;
  border-radius:50%;
  -webkit-box-shadow: 0px 0px 100px 55px rgb(255, 94, 0);
  -moz-box-shadow: 0px 0px 100px 55px rgb(255, 94, 0);
  box-shadow: 0px 0px 100px 55px rgb(255, 94, 0);
  animation: flicker 1000ms linear infinite;
}

.lightbulb {
  pointer-events: none;
  position: absolute;
  height: 100px;
}

.lightbulb-container {
  position: absolute;
}

.bulb-buttons {
  width: 50%;
}

.bulb-buttons-cut-out {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 138px;
  height: 50px;
}

.lightbulb-messages {
  margin-top: -5px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
}

.switch-buttons-container {
  padding-top: 23px;
  margin-bottom: 20px;
}

.typing-text-container {
  padding-bottom: 15px;
  width: 175px;
}

.live-dot-tooltip {
  position: absolute;
  top: 38px;
}

.live-dot-offline {
  width: 10px;
  height: 10px;
  border-radius:50%;
  background-color: grey;
  border: 1px solid rgb(63, 63, 63);
}

.live-dot-online {
  width: 10px;
  height: 10px;
  border-radius:50%;
  background-color: rgb(102, 255, 0);
  -webkit-box-shadow: 0px 0px 10px 3px rgb(102, 255, 0);
  -moz-box-shadow: 0px 0px 10px 3px rgb(102, 255, 0);
  box-shadow: 0px 0px 10px 3px rgb(102, 255, 0);
  border: 1px solid #0003;
}
