.window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.window-title-buttons {
  margin-right: -6px;
  display: flex;
  justify-content: space-between;
}