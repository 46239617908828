.guestbook-window {
  background-color: #ffffcc;
}

.avatar-container {
  padding-right: 10px;
  padding-bottom: 10px;
}

.comment-author-name {
  padding-bottom: 10px;
}

.single-comment-container {
  padding: 15px;
}

.comment-name {
  font-size: 20px;
  font-weight: bold;
}

.bottom-text {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
}

.comment-date {
  font-weight: bold;
}