.terminal-style {
  position: absolute;
  top: 0;

  padding: 5px;

  text-shadow: 0px 0px 10px white;

  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace;
}

.console-text-green {
  color: #39ff38;
}

.console-text-purple {
  color: #f13df4;
}

.console-text-blue {
  color: #45ccf6;
}

.console-text-yellow {
  color: #fff97f;
}

.console-text-red {
  color: #ff5d5d;
}

.blink {
	-webkit-animation: blink .8s linear infinite;
	-moz-animation: blink .8s linear infinite;
	-ms-animation: blink .8s linear infinite;
	-o-animation: blink .8s linear infinite;
	animation: blink .8s linear infinite;
}
@-webkit-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-moz-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-ms-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@-o-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}
@keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity: 0; }
}