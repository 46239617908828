.wd-congrats-message-container {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.wd-congrats-message-text {
  display: block;
  font-size: 20px;
  font-weight: bold;

  text-align: center;
  text-wrap: balance;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;

  background-color: #f9ffcc;
  border: 1px solid;
  padding: 5px;

  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.61);

  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: -55px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity;
  transition-delay: 2s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}