.centered-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-items {
  max-width: 450px;
  border-style: solid;
  padding: 20px;
  color: red;
  background-color:black;
  text-align: center;
  font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace;
}

.moving-grid {
  width: 100%;
  height: 42vh;
  display: block;
  bottom: 0;
  position: absolute;
  opacity: 0.7;
}

.distant-moving-grid {
  width: 100%;
  height: 10vh;
  display: block;
  bottom: 39vh;
  position: absolute;
  mix-blend-mode: screen;
}

.brightness-blend {
  position: absolute;
  width: 100%;
  height: 3vh;
  bottom: 42vh;
  background: rgba(0, 128, 0, 0.5);
}

.gibson-mainframe {
  width: 100%;
  height: 100%;
  display: block;
  bottom: 0;
  position: absolute;
  opacity: 0.8;
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.flip-image {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.web-line {
  position: absolute;
  width: 2px;
  background-color: greenyellow;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}