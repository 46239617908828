.crt-label {
  font-size: 12px;
  position: absolute;
  right: 80px;
  bottom: 2px;
  text-shadow: -1px -1px 1px white;
}

.lcd-label {
  font-size: 12px;
  position: absolute;
  right: 0px;
  bottom: 2px;
  text-shadow: -1px -1px 1px white;
}

.switch-button-container {
  display: flex;
  position: absolute;
  bottom: 2px;
  right: 30px;
}

@media only screen and (max-width: 400px)  {
  .crt-label {
    font-size: 11px;
  }

  .lcd-label {
    font-size: 11px;
  }
}

.switch-container {
  position: absolute;
  right: 20px;
  bottom: 1px;
  opacity: 0.7;
  filter: Alpha(opacity=70);
}

.disabled-switch-bg {
  background-color: orange;
  position: absolute;
  width: 15px;
  height: 6px;
  right: 4px;
  bottom: 4px;
  opacity: 0.9;
  filter: Alpha(opacity=90);
}